import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {Link, graphql } from "gatsby"
import '../styles/poetry.css';
import { Styled,Grid,Box,Message,Badge } from 'theme-ui'


function Poetry({ data }) {
  return (
    <Layout>
    <SEO title="poetry" />
    <h1>Nightsky Poetry</h1>
    <ul style={{display:"flex", flexWrap :"wrap", marginLeft:"0"}}>
    {data.allWordpressCategory.edges.map(({ node }) => (
        <Styled.a as={Link} to={'/category/' + node.name}>
          <li style={{paddingRight: "1.45rem", listStyle:"none"}}>{node.name.charAt(0).toUpperCase() + node.name.slice(1)}
          </li>
          </Styled.a>
    ))}
          </ul>
    <Message style={{backgroundColor:"#838383"}}>
      Choose a poem: click on the link or photo.
      Follow the instructions: The poem will be mailed to you as a pdf.
      If you decide to forward a copy to your friends, it is 
      best to forward it to yourself and put your friends' address in BCC.
      This helps protect their privacy. Pointer: to avoid being junked, copy and paste 
      my email address into your contacts.
    </Message>
    <Grid  gap={4}
      columns={[ 1, null, 3 ]} style={{marginTop:"30px"}}>
    {data.allWordpressWpPoetry.edges.map(({ node }) => (
        <Box  key={'/poetry/' + node.slug}>
        <Styled.a as={Link} to={'/poetry/' + node.slug}>
        {/* <h3 dangerouslySetInnerHTML={{__html: node.title}}/> <br/> <Badge variant='outline' ml={1}>{node.categories[0].name.charAt(0).toUpperCase() + node.categories[0].name.slice(1)}</Badge>
        </h3> */}
        <h3 dangerouslySetInnerHTML={{__html: node.title}} /><br/>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid}
            style={{ padding:2}}/>
        </Styled.a>
        </Box>
    ))}
      </Grid>
  </Layout>
  )
}

export default Poetry
export const query = graphql`
  query {
    allWordpressWpPoetry
    {
        edges {
          node {
            title
            excerpt
            date(formatString: "MMMM DD, YYYY")
            slug
            categories {
                id
                name
              }
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(maxWidth:400, maxHeight:400){
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                      resolutions(width:350, height: 500) {
                        ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                      }
                    }
                }
            }
          }
        }
      }
      allWordpressCategory {
        edges {
          node {
            name
            slug
            wordpress_id
          }
        }
      }
  }
`